import request from '@/utils/request'

export const register = data =>
    request({
        url: `organ/register`,
        // url: `organ/register`,
        method: 'post',
        data
    })

export const login = data =>
    request({
        url: `organ/login`,
        // url: `organ/login`,
        method: 'post',
        data
    })

export const getRsaPublicKey = () =>
    request({
        url: `organ/rsa`,
        // url: `organ/login`,
        method: 'get',
    })

export const organInfo = () =>
    request({
        // url: `organ/organ/baseinfo`,
        url: `organ/baseinfo`,
        method: 'get',
    })

export const getOrgan = (keyword) =>
    request({
        // url: `organ/organ/bind/operator/info/${keyword}`,
        url: `organ/bind/operator/info/${keyword}`,
        method: 'get',
    })

export const remove = () =>
    request({
        // url: `organ/organ/operator/remove`,
        url: `organ/operator/remove`,
        method: 'delete',
    })

export const bind = (data) =>
    request({
        // url: `organ/organ/operator/bind/${phone}`,
        url: `/organ/operator`,
        method: 'put',
        data
    })

export const removeToken = () =>
    request({
        // url: `token/v1/token/organ`,
        url: `token/organ`,
        method: 'delete',
    })