import axios from 'axios'
import { Message } from 'element-ui'
import {
  getOrganToken, getAgentToken
} from '@/utils/auth'
import store from '@/store'

// create an axios instance
// console.log(process.env.VUE_APP_BASE_API)
const service = axios.create({
  baseURL: process.env.NODE_ENV == "development" ? '/api' : process.env.VUE_APP_BASE_API, // api 的 base_url
  // baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (getAgentToken()) {
      config.headers['token'] = getAgentToken()
    }
    if (getOrganToken()) {
      config.headers['token'] = getOrganToken()
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.status_code !== 1) {
      for (const key in res.errmsg) {
        Message({
          message: res.errmsg[key],
          type: 'error',
          duration: 5 * 1000
        })
      }
      //refresh_token无效或者不存在
      if (res.status_code === 22003 || res.status_code === 22006 || res.status_code === 10105) {
        store.dispatch('FedLogOut').then(() => {
          location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
        })
      }
      return Promise.reject('error')
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    if (error.message) {
      if (error.response.data.message) {
        if (error.response.data.message === 'Missing JWT token in request' || error.response.data.message === 'Invalid user key in JWT token') {
          Message({
            message: "token登录时间已过期，请重新登录！",
            type: 'error',
            duration: 5 * 1000
          })
          store.dispatch('FedLogOut').then(() => {
            location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
          })
        }
      }
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
