/**
 * validate email
 * @param email
 * @returns {boolean}
 */
export function validEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}
//弱：纯数字，纯字母，纯特殊字符
export function isPassWord(value) {
    const pPattern = /^.*(?=.{6,})(?:\d+|[a-zA-Z]+|[!@#$%^&*]+).*$/;
    //输出 true
    return pPattern.test(value)
}
export function isSmsCode(value) {
    var mPattern = /^\d{6}$/;
    //输出 true
    return mPattern.test(value)
}
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}
//用户名
export function isUserName(value) {
    //用户名正则，4到16位（字母，数字，下划线，减号）
    var uPattern = /^[a-zA-Z0-9_-]{4,16}$/;
    //输出 true
    return uPattern.test(value)
}
//手机号正则
export function isPhone(value) {
    var mPattern = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
    //输出 true
    return mPattern.test(value)
}