<template>
  <el-main class="app-main">
    <router-view :key="key" @customStyle="customStyle" />
  </el-main>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  methods: {
    customStyle(val) {
      this.$emit("customStyle", val);
    },
  },
};
</script>

<style scoped>
.app-main {
  min-width: 1000px;
  overflow: unset;
  padding: 0;
}
</style>

