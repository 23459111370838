import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/styles/index.scss'

import './icons';

import './permission' // permission control

//Base64
let base64 = require('js-base64').Base64;

//element-ui
import {
  Dialog,
  Input,
  Button,
  Icon,
  Form,
  FormItem,
  Image,
  Checkbox,
  Tabs,
  TabPane,
  Tag,
  Row,
  Col,
  Divider,
  Message,
  Loading,
  MessageBox,
  Select,
  Option,
  Container,
  Header,
  Main,
  Footer,
  Avatar,
  Card,
  Upload,
  scrollbar
} from 'element-ui';


//element-ui
Vue.use(Divider);
Vue.use(Dialog);
Vue.use(Card);
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Checkbox);
Vue.use(Icon);
Vue.use(Image);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Form);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(TabPane);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Upload);
Vue.use(Avatar)
Vue.use(scrollbar)
Vue.use(Loading.directive);

Vue.component(Message.name, Message)
Vue.component(MessageBox.name, MessageBox)

Vue.prototype.$base64 = base64;
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
