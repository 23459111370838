import { getOrganToken, setOrganToken, removeOrganToken, setAgentToken, getAgentToken, removeAgentToken } from '@/utils/auth'
import { login, removeToken } from "@/api/organ";
const user = {
    state: {
        agent_token: getAgentToken(),
        organ_token: getOrganToken()
    },

    mutations: {
        SET_AGENT_TOKEN: (state, agent_token) => {
            state.agent_token = agent_token
        },
        SET_ORGAN_TOKEN: (state, organ_token) => {
            state.organ_token = organ_token
        },
    },

    actions: {
        Login_token({ commit }, userInfo) {
            const { token } = userInfo
            return new Promise((resolve, reject) => {
                if (token) {
                    setAgentToken(token)
                    commit('SET_AGENT_TOKEN', token)
                    resolve()
                } else {
                    reject('token:undefind')
                }
            })
        },
        organ_login({ commit }, userInfo) {
            // console.log(userInfo)
            return new Promise(async (resolve, reject) => {
                const { data } = await login(userInfo)
                if (data) {
                    setOrganToken(data.token)
                    commit('SET_ORGAN_TOKEN', data.token)
                    resolve()
                } else {
                    reject('token:undefind')
                }
            })
        },
        //登出
        LogOut({ commit }) {
            return new Promise((resolve, reject) => {
                removeToken().then(() => {
                    commit('SET_ORGAN_TOKEN', '')
                    commit('SET_AGENT_TOKEN', '')
                    removeOrganToken()
                    removeAgentToken()
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_ORGAN_TOKEN', '')
                commit('SET_AGENT_TOKEN', '')
                removeOrganToken()
                removeAgentToken()
                resolve()
            })
        },
    }
}

export default user
