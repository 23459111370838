import router from './router'
import { getOrganToken } from '@/utils/auth' // 验权

router.beforeEach(async (to, from, next) => {
    const hasToken = getOrganToken()
    const requiresAuth = to.matched.some(r => r.meta.requiresAuth)// 判断该路由是否需要token
    if (hasToken) {
        if (requiresAuth) {
            next()
        } else {
            next({ name: 'personal' })
        }
    } else {
        if (requiresAuth) {
            next({ path: '/' })
        } else {
            next()
        }
    }
})

