import Cookies from 'js-cookie'

const OrganTokenKey = 'Organ-Token'
const AgentTokenKey = 'Agent-Token'
export function getOrganToken() {
    return Cookies.get(OrganTokenKey)
}

export function setOrganToken(token) {
    return Cookies.set(OrganTokenKey, token)
}

export function removeOrganToken() {
    return Cookies.remove(OrganTokenKey)
}
export function getAgentToken() {
    return Cookies.get(AgentTokenKey)
}

export function setAgentToken(token) {
    return Cookies.set(AgentTokenKey, token)
}

export function removeAgentToken() {
    return Cookies.remove(AgentTokenKey)
}