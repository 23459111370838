import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'organ',
    redirect: { name: 'login' },
    component: Layout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index')
      },
      {
        path: '/registered',
        name: 'registered',
        component: () => import('@/views/registered/index')
      },
      {
        path: '/personal',
        name: 'personal',
        component: () => import('@/views/personal/index'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
