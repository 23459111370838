<template>
  <el-container v-loading="allloading">
    <el-header :class="isHeaderStyle">
      <div class="nav">
        <div class="left-wrapper">
          <div class="imager">
            <img src="../../assets/image/login_logo.png" />
          </div>
          <div class="font">上海岐素信息科技有限公司</div>
        </div>
        <div class="right-wrapper" v-show="showLogout">
          <div class="text">
            <span>返回官网</span>
            <el-divider direction="vertical"></el-divider>
            <span @click="logout">退出</span>
          </div>
        </div>
      </div>
    </el-header>
    <app-main @customStyle="customStyle" />
    <el-footer :class="isFooterStyle">
      <div class="a-wrapper">
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202008088"
          target="_blank"
        >
          <img width="15px" src="../../assets/image/beiantubiao.png" />
          沪公网安备31011202008088号
        </a>
        <el-divider direction="vertical"></el-divider>
        <a>沪ICP备17034514号</a>
        <el-divider direction="vertical"></el-divider>
        <a>增值电信业务经营许可证:沪B2-20190305</a>
        <a>高新技术企业 GR201931005756</a>
        <el-divider direction="vertical"></el-divider>
        <a>地址：上海市闵行区莘建东路绿地大厦A座907室</a>
        <!-- <el-divider direction="vertical"></el-divider> -->
        <!-- <a>营业执照</a> -->
        <!-- <el-divider direction="vertical"></el-divider>
        <a>联系电话：511111111</a>-->
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import { AppMain } from "./components";
export default {
  name: "Layout",
  components: { AppMain },
  provide() {
    return {
      loading: this.loading,
    };
  },
  data() {
    return {
      isHeaderStyle: "header-0",
      isFooterStyle: "footer-0",
      showLogout: false,
      allloading: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    loading() {
      this.allloading = !this.allloading;
    },
    customStyle: function (val) {
      switch (val) {
        case 0:
          this.isHeaderStyle = "header-0";
          this.isFooterStyle = "footer-0";
          break;
        case 1:
          this.isHeaderStyle = "header-1";
          this.isFooterStyle = "footer-1";
          break;
        case 2:
          this.isHeaderStyle = "header-2";
          this.isFooterStyle = "footer-1";
          break;
        case 3:
          this.isHeaderStyle = "header-2";
          this.isFooterStyle = "footer-1";
          this.showLogout = true;
          break;
        default:
          console.log(val);
          break;
      }
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.el-container {
  width: 100%;
  height: 100%;
  .header-0 {
    background-color: #fff;
  }
  .header-1 {
    position: fixed;
    width: 100%;
    height: calc(50px - 1px) !important;
    background-color: rgba(0, 0, 0, 0);
    color: #fff !important;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-color: #ffffff;
      opacity: 0.4;
    }
  }
  .header-2 {
    background: url("../../assets/image/pc_top.png") no-repeat;
    color: #fff !important;
  }
  .footer-0 {
    background-color: #fff;
  }
  .footer-1 {
    background: url("../../assets/image/pc_lower.png") no-repeat;
    a {
      color: #fff !important;
    }
  }
  .el-header,
  .el-footer {
    z-index: 999;
    min-width: 1000px;
    padding: 0;
    color: #586883;
    text-align: center;
    line-height: 50px;
  }
  .el-header {
    height: 50px !important;
    .nav {
      margin: 0 auto;
      max-width: 1800px;
      height: 100%;
      box-sizing: border-box;
      position: relative;
      z-index: 2000;
      .left-wrapper {
        float: left;
        display: flex;
        height: 100%;
        padding: 2px 0;
        box-sizing: border-box;
        .imager {
          img {
            width: 50px;
            height: 22px;
          }
        }
        .font {
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 1px;
        }
      }
      .right-wrapper {
        float: right;
        display: flex;
        height: 100%;
        padding: 2px 0;
        box-sizing: border-box;
        .text {
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 1px;
        }
      }
      &::after {
        content: "";
        clear: both;
      }
    }
  }
  .el-footer {
    padding-top: 8px;
    .a-wrapper {
      display: block;
      width: 570px;
      height: auto;
      font-size: 10px;
      line-height: 22px;
      margin: 0 auto;
      // padding-top: 5px;
      box-sizing: border-box;
      a {
        color: #333333;
        // &:visited {
        //   color: #4c84c1;
        // border-bottom: 1px solid #4c84c1;
        // }
        &:hover {
          opacity: 0.9;
        }
        &:active {
          opacity: 0.9;
        }
      }
    }
  }
}
</style>